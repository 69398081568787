/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useEffect, useState } from 'react';
import './styles/common.scss';
import './App.scss';
import { CssBaseline, Container, Box, Paper, Grid } from '@mui/material';
import { Header } from './modules/header/components/Header';
import { Routers } from './shared/Routers';
import { ThemeProvider } from '@mui/material/styles';
import WASHUB_APP_THEME from './shared/Theme';
import { AuthenticationContext } from './shared/Contexts';
import { SpinnerBackdrop } from './shared/components/spinner-backdrop/SpinnerBackdrop';
import { LaundryCartProvider } from './modules/orders/context/service.provider';
import { AlertBar } from './shared/components/alert-bar/AlertBar';
import { UserDataProvider } from './modules/user-order/context/service.provider';
import { Menunav } from './modules/menu/components/Menu';
import { useLocation } from 'react-router-dom';
import { UserOrder } from './modules/user-order/UserOrder';
import Sidebar from './collectionCenter/modules/SideBar';
import CCHeader from './collectionCenter/modules/Header';
import { BusinessProvider } from "./shared/BusinessContext";
import { OrderCenter } from './orderCenter/modules/OrderCenter';
import { OrderProvider } from './shared/OrderContext';

function App() {
  const location = useLocation();

  useEffect(() => {
    if (
      authenticate.urlPath.includes('retailCustomerOrder') ||
      authenticate.urlPath.includes('businessStoreOrder') ||
      authenticate.urlPath.includes('businessCustomerOrder')
    ) {
      const params = new URLSearchParams(location.search);
      const keyValue = params.get('key');
      if (keyValue) {
        localStorage.setItem('key', keyValue);
      }
    }
  }, [location]);
  const myStyle = {
    color: 'white',
    backgroundColor: '#25BCCA',
    padding: '10px',
    fontFamily: 'Sans-Serif',
  };
  const authenticate = useContext(AuthenticationContext);
  return (
    <ThemeProvider theme={WASHUB_APP_THEME}>
      <LaundryCartProvider>
        <UserDataProvider>
          <div className="App">
            <CssBaseline />
            <Container
              maxWidth="xl"
              sx={{ padding: '0px', borderRadius: '0 0 40px 40px' }}
            >
              <Box sx={{ bgcolor: '#FDFFFE' }}>
                {authenticate.urlPath.includes('retailCustomerOrder') ||
                authenticate.urlPath.includes('businessStoreOrder') ||
                authenticate.urlPath.includes('business-clothes-selection') ||
                authenticate.urlPath.includes('clothes-selection') ||
                authenticate.urlPath.includes('checkout') ||
                authenticate.urlPath.includes('business-checkout') ||
                authenticate.urlPath.includes('businessCustomerOrder') ||
                authenticate.urlPath.includes('service-type') ? (
                  <>
                    <UserOrder />
                    <Routers />
                  </>
                ) : authenticate.urlPath.includes('OrderCenter') ? (
                  <OrderProvider>
                      <OrderCenter />
                  </OrderProvider>
                ) : authenticate.token && authenticate.userRole !== 'null' ? (
                  <BusinessProvider>
                    <Paper sx={{overflow: "hidden"}}>
                      <Header></Header>
                      <Grid container display={'flex'} flexDirection={'row'} sx={{height: "calc(100vh - 11vh)"}}>
                        <Grid item xs={12} sm={12} md={3} lg={2}>
                          <Menunav />
                        </Grid>
                        <Grid item xs={12} sm={12} md={9} lg={10}>
                          <Routers />
                        </Grid>
                      </Grid>
                    </Paper>
                  </BusinessProvider>
                ) : authenticate.token && authenticate.userRole === 'null' ? (
                  <>
                    <Paper sx={{ height: '100vh' }}>
                      <CCHeader />
                      <Grid
                        container
                        display={'flex'}
                        flexDirection={'row'}
                        id="secondContent"
                      >
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <Sidebar />
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} lg={10}>
                          <Routers />
                        </Grid>
                      </Grid>
                    </Paper>
                  </>
                ) : (
                  <>
                    <div style={myStyle}>
                      {authenticate.urlPath === '/' ? (
                        <h1>Klean Fabric System</h1>
                      ) : (authenticate.urlPath.includes('OrderCenter')) ? (
                        <h1>Order Center</h1>
                      ) : (
                        <h1>Collection Center</h1>
                      )}
                    </div>
                    <Routers />
                  </>
                )}
                <SpinnerBackdrop />
                <AlertBar />
              </Box>
            </Container>
          </div>
        </UserDataProvider>
      </LaundryCartProvider>
    </ThemeProvider>
  );
}

export default App;
