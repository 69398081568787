import {
  Button,
  Card,
  CardContent,
  DialogContentText,
  Divider,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAPI } from '../../../shared/services/api/API';
import React from 'react';
import { UserDataContext } from '../../../modules/user-order/context/service.context';
import { WASHUB_CONSTANTS } from '../../../shared/Constants';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import InfoIcon from '@mui/icons-material/Info';
import SuccessModal from '../../../shared/SuccessModal';
import { CustomButton } from '../CustomButton';
import { OrderContext } from '../../../shared/OrderContext';

export const OrderServiceType = () => {
  const [serviceTypeList, setServiceTypeList] = useState([]);
  const [serviceTypeId, setServiceTypeId] = useState(`2`);
  const [serviceCharge, setServiceCharge] = useState<number>(0);
  const [chargePertentage, setChargePercentage] = useState<number>(0);
  const [vatPercentage, setVatPercentage] = useState<number>(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { httpGet, httpPost } = useAPI();
  const totalQuantity = location.state.totalQuantity;
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const todayDate = new Date();
  const end = new Date();
  const tomorrowDate = end.setDate(end.getDate() + 1);
  const [orderNote, setOrderNote] = useState('');
  const [deliveryData, setDeliveryData] = useState({
    order: '',
    pickupDate: '',
    deliveryDate: '',
  });
  const [open, setOpen] = useState(false);
  const buid = localStorage.getItem('buid');
  const key = localStorage.getItem('key');
  const [billNo, setBillNo] = useState('')
  const [pickupDate, setPickupDate] = useState(dayjs(todayDate));
  const [deliveryDate, setDeliveryDate] = useState(dayjs(tomorrowDate));
  const {
    busStoreId,
    deptId,
    serviceItemOrder,
    selectedItems,
    updateBusStoreId,
    updateDeptId,
    updateServiceItemOrder,
    updateSelectedItems,
  } = useContext(OrderContext);
  const handlePickupDateChange = (date: any) => {
    setPickupDate(date);
    setDeliveryDate(date.add(1,'day'));
  };
  const handleDeliveyDateChange = (date: any) => {
    setDeliveryDate(date);
  };
  const handleBack = () => {
    navigate('..');
  };
  const handleServiceTypeData = (id: string) => {
    setServiceTypeId(id);
    const data: any = serviceTypeList.find((e: any) => e.id === +id);
    setDeliveryDate(dayjs());
  };

  useEffect(() => {
    fetchBusinessStoreServiceTypeData();
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    if (+serviceTypeId === 1) {
      if (currentHour >= 12) {
        const laterDate = new Date(currentDate.getTime() + 12 * 60 * 60 * 1000);
        if(dayjs(currentDate) < pickupDate) {
          setDeliveryDate(dayjs(pickupDate));
        } else {
          setPickupDate(dayjs(currentDate));
          setDeliveryDate(dayjs(laterDate));
        } 
      } else {
        setPickupDate(dayjs(currentDate));
        setDeliveryDate(dayjs(currentDate));
      }
    } else {
      if (currentHour <= 24) {
        const laterDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
        setPickupDate(dayjs(currentDate));
        setDeliveryDate(dayjs(laterDate));
      }
    }
  }, [serviceTypeId]);

  const fetchBusinessStoreServiceTypeData = async () => {
    await httpGet(
      `${WASHUB_CONSTANTS.API_URLS.BUSINESS_ORDER_PICKUP_SERVICE_TYPE_DATA_GET}`,
    ).then((response) => {
      if (response.status === 200) {
        setServiceTypeList(response?.data);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching Business Store Department: ',
            response.data.message,
          );
        }
      }
    });
  };

  const createBusinessOrder = async () => {
    const serviceItems = serviceItemOrder?.map((item: any) => {
      return {
        specialInstructions: 'string',
        amount: item.price * item.quantity,
        itemCount: item.quantity,
        categoryId: item.category.id,
        serviceItemId: item.id,
      };
    });
    const data = {
      businessId: buid,
      businessStoreId: +busStoreId,
      serviceItemOrder: serviceItems,
      departmentId: +deptId,
      serviceTypeId: +serviceTypeId,
      pickupDate: pickupDate.format('YYYY-MM-DD'),
      deliveryDate: deliveryDate.format('YYYY-MM-DD'),
      orderNote: orderNote,
      billNo: billNo
    };
    try {
      const response = await httpPost(
        `${WASHUB_CONSTANTS.API_URLS.BUSINESS_ORDER_PICKUP_CREATE_DATA_POST}/${busStoreId}`,
        data,
      );
      if (response.status === 200) {
        setOpen(false);
        setIsSuccessModal(true);
        updateBusStoreId('');
        updateDeptId('');
        updateServiceItemOrder([]);
        updateSelectedItems({});
        setOrderNote('');
        setDeliveryData({
          order: response.data.order,
          pickupDate: pickupDate.format('YYYY-MM-DD'),
          deliveryDate: deliveryDate.format('YYYY-MM-DD'),
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const submitOrder = () => {
    createBusinessOrder();
  };

  return (
    <Grid container position={'relative'} top={'-10px'}>
      <Grid
        container
        display={'flex'}
        justifyContent={'center'}
        style={{
          backgroundColor: '#045464',
          padding: '5px 15px 15px',
          //   borderRadius: '0px 0px 35px 35px',
        }}
      >
        <Grid item md={0.5} lg={0.5}>
          <Link
            component="button"
            title="back to user detail"
            onClick={handleBack}
          >
            <KeyboardArrowLeftIcon
              fontSize="large"
              style={{ color: '#ffffff' }}
            />
          </Link>
        </Grid>
        <Grid
          item
          xs={10}
          sm={11}
          md={11}
          lg={11}
          display={'flex'}
          justifyContent={'center'}
        >
          <Typography
            variant="h5"
            className="mb-0"
            color={'white'}
            sx={{ padding: '3px' }}
          >
            Select Service Type
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        display={'flex'}
        justifyContent={'space-between'}
        sx={{ padding: '20px' }}
        spacing={2}
      >
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                size='small'
                key="serviceType"
                label="Select Service Type"
                name="serviceType"
                className="mt-3"
                select
                onChange={(e) => {
                  handleServiceTypeData(e.target.value);
                }}
                value={serviceTypeId}
              >
                {serviceTypeList.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {+serviceTypeId === 1 && (
              <div className="mx-3">
                <InfoIcon color="warning" sx={{ fontSize: '1rem' }} />
                <span style={{ fontSize: '0.8rem' }}>
                  Same day delivery charges apply
                </span>
              </div>
            )}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'Pickup Date'}
                  slotProps={{ textField: { variant: 'standard' } }}
                  name="pickupDate"
                  value={dayjs(pickupDate)}
                  // minDate={dayjs(todayDate)}
                  onChange={handlePickupDateChange}
                  sx={{width: '45%' }}
                  className='ms-1 me-4'
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'Delivery date'}
                  slotProps={{ textField: { variant: 'standard' } }}
                  name="deliveryDate"
                  value={dayjs(deliveryDate)}
                  minDate={dayjs(pickupDate)}
                  onChange={handleDeliveyDateChange}
                  sx={{width: '45%' }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
          <TextField
                  fullWidth
                  size='small'
                  key="billNo"
                  label="Bill Number"
                  name="billNo"
                  className="mt-3 me-3"
                  onChange={(e) => {
                    setBillNo(e.target.value);
                  }}
                  value={billNo}
                  required
                >
          </TextField>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
          <TextField
              id="outlined-helperText"
              label="Add Notes"
              fullWidth
              value={orderNote}
              onChange={(e) => setOrderNote(e.target.value)}
              multiline
              rows={2}
            />
          </Grid>
        </Grid>
        </Grid>
      </Grid>
      <div
        style={{
          height: '42vh',
          overflow: 'auto',
          padding: '5px 20px',
          width: '100%',
        }}
      >
        <Typography className="body1">Order Details</Typography>
        <Card
          sx={{ minWidth: 275, maxHeight: '250px', overflow: 'auto' }}
          elevation={2}
        >
          <CardContent>
            {serviceItemOrder?.length > 0 &&
              serviceItemOrder.map((item: any, index: number) => (
                <Grid
                  key={index}
                  container
                  display={'flex'}
                  direction={'row'}
                  justifyContent={'space-between'}
                >
                  <Grid item>
                    <DialogContentText
                      sx={{ fontSize: '16px', color: 'black' }}
                    >
                      {item?.name}
                    </DialogContentText>
                  </Grid>
                  <Grid item>
                    <DialogContentText
                      sx={{ fontSize: '16px', color: 'black' }}
                    >
                      {item?.quantity}
                    </DialogContentText>
                  </Grid>
                </Grid>
              ))}

            <Divider sx={{ bgcolor: '#045464', margin: '10px 5px' }} />
            <Grid
              container
              display={'flex'}
              direction={'row'}
              justifyContent={'space-between'}
            >
              <Grid item>
                <Typography variant="h5">Total Count</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5">{totalQuantity} items </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          padding: ' 20px',
          left: '50%',
          transform: ' translateX(-50%)',
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomButton
              color="primary"
              onClick={submitOrder}
              variant="contained"
              disabled={billNo === '' || totalQuantity === 0}
              label={'Place order'}
            />
          </Grid>
        </Grid>
      </div>
      {isSuccessModal && (
        <SuccessModal
          isOpen={isSuccessModal}
          closeModal={setIsSuccessModal}
          deliveryData={deliveryData}
          isBusiness={false}
          isBusinessStoreOrder={false}
          isPickupOrder={true}
        />
      )}
    </Grid>
  );
};
