import React, { useState } from 'react';
import { useAPI } from '../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../shared/Constants';

export const commonApi = () => {
    const [allBusinessStoreList, setAllBusinessStoreList] = useState<any>([]);
    const [allBusinessStoreDepartmentList, setAllBusinessStoreDepartmentList] = useState<any>([]);
    const { httpGet } = useAPI();

    const getAllBusinessStore = async () => {
        try {
          const response = await httpGet(
            WASHUB_CONSTANTS.API_URLS.BUSINESS_ORDER_PICKUP_STORE_DATA_GET,
          );
          if (response.status === 200) {
            const sortBusinessStoreArray = response?.data?.sort((a: any,b: any) => a?.name.toLowerCase().localeCompare(b.name.toLowerCase()));
            setAllBusinessStoreList(sortBusinessStoreArray);
          }
        } catch (error) {
          console.error('Error fetching all bus products:', error);
        }
    };

    const getAllBusinessStoreDepartment = async (storeId: string) => {
      try {
        const response = await httpGet(
          `${WASHUB_CONSTANTS.API_URLS.BUSINESS_ORDER_PICKUP_DEPT_DATA_GET}/${storeId}`,
        );
        if (response.status === 200) {
          const sortBusinessDepartmentArray = response?.data?.sort((a: any,b: any) => a?.name.toLowerCase().localeCompare(b.name.toLowerCase()));
          setAllBusinessStoreDepartmentList(sortBusinessDepartmentArray);
        }
      } catch (error) {
        console.error('Error fetching all bus products:', error);
      }
  };
  return { 
    getAllBusinessStore, 
    allBusinessStoreList, 
    getAllBusinessStoreDepartment, 
    allBusinessStoreDepartmentList,
  }
}