import { Button, Container, Grid, Stack } from '@mui/material';
import React from 'react';
import './OrderHome.scss';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../CustomButton';

export const OrderHome = () => {
  const navigate = useNavigate();

  const goToPickup = () => {
    navigate('/OrderCenter/pickup-order');
  };

  const goToUpdateOrder = () => {
    navigate('/OrderCenter/update-order');
  };
  return (
    <>
      <div className="order-center-main">
        <img
          className="main-img"
          src="../../../../images/laundry-order2.png"
          alt="Login Icon"
        />
      </div>
      <div className="footer">
        <CustomButton
          className="footer-button mx-2"
          variant="contained"
          label="Update Order"
          onClick={goToUpdateOrder}
        />
        <CustomButton
          className="footer-button mx-2"
          variant="contained"
          label="Pickup Order"
          onClick={goToPickup}
        />
      </div>
    </>
  );
};
