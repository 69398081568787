import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Chip, DialogContentText, Divider, Grid, InputAdornment, Link, MenuItem, Paper, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useLocation, useNavigate } from 'react-router-dom';
import { commonApi } from '../../common-api/commonApi';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAPI } from '../../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../../shared/Constants';
import { ClothesSelectionList } from '../../../modules/user-order/components/clothes-selection-list/ClothesSelectionList';
import { CustomButton } from '../CustomButton';
import { OrderContext } from '../../../shared/OrderContext';
import './PickupOrder.scss';

export const PickupOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const serviceItemSelected = location?.state?.orderDetails?.serviceItemOrder;
  const { httpGet } = useAPI();
  const [businessStoreList, setBusinessStoreList] = useState<any>([]);
  const [departmentList, setDepartmentList] = useState<any>([]);
  const [serviceItemList, setServiceItemList] = useState<any>([]);
  const [serviceCategories, setServiceCategories] = useState<any>([]);
  const [selectedServiceCategory, setSelectedServiceCategory] = useState('');
  const [serviceItemSection, setServiceItemSection] = useState<any>([]);
  const [itemTax, setItemTax] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalQuantity, setQuantity] = useState(0);
  const [vatPercentage, setVatPercentage] = useState<number>(0);
  const [searchQueries, setSearchQueries] = useState<any>({});
  const [expanded, setExpanded] = useState<string | boolean>(false);
  const handleExpanded = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean)  => {
    setExpanded(isExpanded ? panel : false)
  }
  const { 
    busStoreId , 
    deptId,
    serviceItemOrder,
    selectedItems,
    updateBusStoreId, 
    updateDeptId,
    updateServiceItemOrder,
    updateSelectedItems
   } = useContext(OrderContext);
  const { 
    getAllBusinessStore, 
    allBusinessStoreList, 
    getAllBusinessStoreDepartment, 
    allBusinessStoreDepartmentList,
  } = commonApi();

  const handleBack = () => {
    navigate(`/OrderCenter/home`);
  };

  const handleServiceCategories = async (category: any) => {
    setSelectedServiceCategory(category);
    const list: any = serviceItemList?.find(
      (item: any) => category === item.categoryName,
    );
    console.log(list)
    await setServiceItemSection([list]);
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchInput(event.target.value);
  };

  const handleSearchInputChangeAccordion = (
    sectionName: any,
    event: any,
  ) => {
    setSearchQueries({
      ...searchQueries,
      [sectionName]: event?.target?.value.toLowerCase()
    });
  };

  const filteredClothesList = serviceItemSection?.map((section: any)=> ({...section,optiServiceItem : section?.optiServiceItem.filter((item: any) => item.name?.toLowerCase().includes(searchQueries[section?.name] ? searchQueries[section?.name] : '')) }))
  const filteredOptiServiceItem = serviceItemSection?.map((section: any)=> ({...section,optiServiceItem : section.optiServiceItem.filter((item: any) => item.name?.toLowerCase().includes(searchInput?.toLowerCase())) }))

  const removeItem = (item: any) => {
    const index = serviceItemOrder.findIndex(
      (itemDetail: any) => itemDetail.id === item.id,
    );
    if (index !== -1) {
      const updatedItemDetail = [...serviceItemOrder];
      if (updatedItemDetail[index].quantity === 1) {
        updatedItemDetail.splice(index, 1);
      } else {
        updatedItemDetail[index].quantity--;
      }
      updateServiceItemOrder(updatedItemDetail);
    }
    const updatedItems: any = { ...selectedItems };
    if (updatedItems[item?.id]) {
      updatedItems[item?.id]--;
      if (updatedItems[item?.id] === 0) {
        delete updatedItems[item?.id];
      }
      updateSelectedItems(updatedItems);
    }
  };

  const addItem = (item: any) => {
    const index = serviceItemOrder.findIndex(
      (itemDetail: any) => itemDetail.id === item.id,
    );
    if (index !== -1) {
      const updatedItemDetail = [...serviceItemOrder];
      updatedItemDetail[index].quantity++;
      updateServiceItemOrder(updatedItemDetail);
    } else {
      updateServiceItemOrder([...serviceItemOrder, { ...item, quantity: 1 }]);
    }
    const updatedItems = { ...selectedItems };
    updatedItems[item?.id] = updatedItems[item?.id]
      ? updatedItems[item?.id] + 1
      : 1;
      updateSelectedItems(updatedItems);
  };

  const getAllBusinessStoreServiceItem = async (storeId: string) => {
    try {
      const response = await httpGet(
        `${WASHUB_CONSTANTS.API_URLS.BUSINESS_ORDER_PICKUP_STORE_SERVICEITEM_DATA_GET}/${storeId}`,
      );
      if (response.status === 200) {
        setServiceItemList(response.data?.retailServiceItem);
        setServiceCategories(response.data?.serviceCategories);
        setItemTax(response.data?.taxPercentage);
        setSelectedServiceCategory(response.data?.serviceCategories ? response.data?.serviceCategories[0] : '');
      }
    } catch (error) {
      console.error('Error fetching all bus products:', error);
    }
};

const getAllBusinessDepartmentServiceItem = async (storeId: string, deptId: string) => {
  try {
    const response = await httpGet(
      `${WASHUB_CONSTANTS.API_URLS.BUSINESS_ORDER_PICKUP_DEPT_SERVICEITEM_DATA_GET}/${storeId}/${deptId}`,
    );
    if (response.status === 200) {
      setServiceItemList(response.data?.retailServiceItem);
      setServiceCategories(response.data?.serviceCategories);
      setItemTax(response.data?.taxPercentage);
      setSelectedServiceCategory(response.data?.serviceCategories ? response.data?.serviceCategories[0] : '');
    }
  } catch (error) {
    console.error('Error fetching all bus products:', error);
  }
};

  useEffect(() => {
    getAllBusinessStore();
  }, []);

  useEffect(() => {
    if (busStoreId) {
      // getAllBusinessStoreServiceItem(busStoreId);
      getAllBusinessStoreDepartment(busStoreId);
    }
  }, [busStoreId]);

  useEffect(() => {
    if (deptId && departmentList) {
      setSearchQueries({});
      getAllBusinessDepartmentServiceItem(busStoreId,deptId);
    }
  }, [departmentList, deptId]);

  useEffect(() => {
    if (allBusinessStoreList) {
      setBusinessStoreList(allBusinessStoreList);
    }
    if(allBusinessStoreDepartmentList) {
      setDepartmentList(allBusinessStoreDepartmentList);
    }
  }, [
    allBusinessStoreList, 
    allBusinessStoreDepartmentList, 
  ]);

  useMemo(() => {
    if (serviceItemOrder) {
      const updatedItem: any = {};
      let total = 0;
      let quantity = 0;
      serviceItemOrder?.map((item: any) => {
        updatedItem[item.id] = item.quantity;
        updateSelectedItems(updatedItem);
        total += item.price * item.quantity;
        quantity += item.quantity;
      });
      updateServiceItemOrder(serviceItemOrder);
      setQuantity(totalQuantity + quantity);
      setTotalPrice(totalPrice + total);
    }
  }, []);

  useEffect(() => {
    if (serviceItemList?.length > 0) {
      handleServiceCategories(serviceCategories ? serviceCategories[0] : '');
    }
  }, [serviceItemList]);

  const itemChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: any,
  ) => {
    const { value } = event.target;
    let quantity = parseInt(value, 10);

    if (isNaN(quantity) || quantity < 0) {
      quantity = 0;
    }
    // Update selectedItems state
    updateSelectedItems((prevItems: any) => ({
      ...prevItems,
      [item.id]: quantity,
    }));

    updateServiceItemOrder((prevOrder: any) => {
      const itemIndex = prevOrder.findIndex(
        (orderItem: any) => orderItem.id === item.id,
      );
      if (itemIndex !== -1) {
        const updatedOrder = [...prevOrder];
        updatedOrder[itemIndex].quantity = quantity;
        return updatedOrder;
      }
      return [...prevOrder, { ...item, quantity }];
    });
  };
  useEffect(() => {
    const totalQuantity = serviceItemOrder.reduce(
      (acc: any, item: any) => acc + item.quantity,
      0,
    );
    setQuantity(totalQuantity);
    const updatedItems = serviceItemOrder.map((item: any) => {
      return { ...item, totalPrice: item.price * item.quantity };
    });
    const overallTotalPrice = updatedItems.reduce(
      (acc: any, item: any) => acc + item.totalPrice,
      0,
    );
    setTotalPrice(overallTotalPrice);
  }, [serviceItemOrder]);

  useEffect(() => {
    const vat = (totalPrice * +itemTax) / 100;
    setVatPercentage(vat);
  }, [vatPercentage, totalPrice]);

  const submitHandler = () => {
    navigate('order-service-type', {
      state: {
        totalPrice: totalPrice,
        serviceItemOrder: serviceItemOrder,
        totalQuantity: totalQuantity,
        isFrom: "pickupOrder"
      },
    });
  };

  return (
    <div>
      <Grid
        container
        display={'flex'}
        justifyContent={'center'}
        style={{
          backgroundColor: '#045464',
          padding: '10px',
          borderRadius: 'none',
        }}
      >
        <Grid item md={0.5} lg={0.5}>
          <Link
            component="button"
            title="back to user detail"
            onClick={handleBack}
          >
            <KeyboardArrowLeftIcon
              fontSize="large"
              style={{ color: '#ffffff' }}
            />
          </Link>
        </Grid>
        <Grid
          item
          xs={10}
          sm={11}
          md={11}
          lg={11}
          display={'flex'}
          justifyContent={'center'}
        >
          <Typography
            variant="h5"
            className="mb-0"
            style={{ color: '#ffffff', padding: '3px' }}
          >
            Select Laundry Items
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} marginTop={2} padding={'0px 10px'}>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <TextField
            fullWidth
            key="businessStore"
            label="Select Business Store"
            name="businessStore"
            select
            onChange={(e) => {
              updateBusStoreId(e.target.value);
            }}
            value={busStoreId}
          >
            {businessStoreList.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          {departmentList.length > 0 ? (
            <TextField
              fullWidth
              key="department"
              label="Select Department"
              name="department"
              select
              onChange={(e) => {
                updateDeptId(e.target.value);
              }}
              value={deptId}
            >
              {departmentList.map((option: any) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          ) : null}
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        marginTop={2}
        padding={'0px 10px'}
        sx={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Grid sx={{ display: 'flex', flexWrap: "wrap" }}>
          {serviceCategories.length > 0 && serviceCategories?.map((item: string, index: number) => (
            <Grid item key={item}>
              <Chip
                color={"primary"}
                size="medium"
                id="buttons"
                variant={selectedServiceCategory === item ? 'filled' : 'outlined'}
                className="m-2"
                label={item}
                onClick={() => handleServiceCategories(item)}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item>
          {filteredOptiServiceItem?.length !== 0 && (
            <TextField
              variant="outlined"
              placeholder="Search..."
              id="inputSearchItems"
              value={searchInput}
              onChange={handleSearchInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& input': {
                    padding: '8px 14px', // Adjust the padding to fit the new height
                  },
                },
              }}
            />
          )}
        </Grid>
      </Grid>
      <div className='filter-cloth-list'>
        {filteredOptiServiceItem?.length > 0 ? (
          filteredOptiServiceItem?.length === 1 ? (
            filteredOptiServiceItem.map((data: any, i: any) => (
              <ClothesSelectionList
                filteredClothesList={data?.optiServiceItem}
                removeItem={removeItem}
                key={i}
                addItem={addItem}
                itemChangeHandler={itemChangeHandler}
                selectedItems={selectedItems}
                useFor={"pickupOrder"}
              />
            ))
          ) : (
            filteredClothesList.map((data: any, index: any) => (
              <Accordion
              expanded={expanded === `panel${index}`}
              onChange={handleExpanded(`panel${index}`)}
              key={`panel${index}`}
                sx={{
                  backgroundColor: 'transparent',
                  border: '1px solid #045464',
                  padding: '0.5rem 0.5rem',
                  margin: '0.5rem 0rem',
                }}
              >
                <AccordionSummary
                  sx={{
                    padding: '0px 5px',
                    textAlign: 'center',
                    margin: '0px',
                    '& .css-13tsglk-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded':
                      {
                        minHeight: '0px',
                        '& .css-eqpfi5-MuiAccordionSummary-content.Mui-expanded':
                          {
                            margin: '0px',
                          },
                      },
                  }}
                  style={{ minHeight: '35px', height: '40px' }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Grid
                    container
                    display={'flex'}
                    direction={'row'}
                    justifyContent={'space-between'}
                  >
                    <Grid item>
                      <DialogContentText
                        sx={{
                          fontSize: '17px',
                          fontWeight: 'bold',
                          color: 'black',
                        }}
                      >
                        {data?.serviceItemSectionName}
                      </DialogContentText>
                    </Grid>
                    <Grid item>
                        <TextField
                            key={data?.id}
                            variant="outlined"
                            placeholder="Search..."
                            id="inputSearchItem"
                            // value={item?.searchInput}
                            onChange={(e) =>handleSearchInputChangeAccordion(data?.name,e)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& input': {
                                  padding: '8px 14px', // Adjust the padding to fit the new height
                                },
                              },
                            }}
                          />
                      </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0px',
                  }}
                >
                  <ClothesSelectionList
                    filteredClothesList={data?.optiServiceItem}
                    removeItem={removeItem}
                    addItem={addItem}
                    itemChangeHandler={itemChangeHandler}
                    selectedItems={selectedItems}
                    useFor={"pickupOrder"}
                  />
                </AccordionDetails>
              </Accordion>
            ))
          )
        ) : (
          <></>
        )}
      </div>
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          padding: '10px 20px',
          left: '50%',
          transform: ' translateX(-50%)',
        }}
        elevation={3}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display={'flex'}
            justifyContent={'center'}
          >
            <CustomButton
                color="primary"
                onClick={submitHandler}
                variant="contained"
                disabled={totalQuantity === 0}
                label={"Select Service Type"}
              />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
