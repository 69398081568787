import React, { useContext, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, DialogContentText, Grid, IconButton, InputAdornment, Modal, TextField, Typography } from '@mui/material';
import { Icons } from '../../../../assets/icons';
import {
  TCategoryType,
  getCategoryColor,
  getGroupByCategory,
  getGroupByCategoryWithSectionName
} from '../../../../shared/Constants';
import { LaundryCartContext } from '../../context/service.context';
import './categorywidget.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { CartList } from '../cart-list/CartList';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  outline: 'none',
  justifyContent: 'center',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  p: '0',
};

const CategoryWidget = () => {
  const { data, setData } = useContext(LaundryCartContext);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [ clothesFilterList, setClothesFilterList] = useState<any>([]);
  const [ selectService, setSelectService] = useState(clothesFilterList[0]);
  const [searchInput, setSearchInput] = useState('');
  const [searchQueries, setSearchQueries] = useState<any>({});
  const [expanded, setExpanded] = useState<string | boolean>(false);
  const [clothesList, setClothesList] = useState<any>([]);
  const handleExpanded = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean)  => {
    setExpanded(isExpanded ? panel : false)
  }
  useEffect(() => {
    setSelectedItem(null);
      const filterList = Object.keys(getGroupByCategory(data))
      setClothesFilterList(filterList);
  }, [data]);

  useEffect(() => {
    if (selectedItem) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [selectedItem]);

   useEffect(() =>{
    const isService = clothesFilterList.includes(selectService);
    handleFilterService(isService ? selectService : clothesFilterList[0])
   },[selectService, clothesFilterList ])

  const handleModalClose = (event: any, reason: any) => {
    if (reason && reason === 'backdropClick') return;
  };

  const increaseQuantity = (id: any) => {
    const currentItems = [...data];
    const index = currentItems.findIndex(
      (itemDetail: any) => itemDetail.id === id,
    );
    currentItems[index].quantity += 1;
    setData(currentItems);
  };

  const decreaseQuantity = (id: any) => {
    const currentItems = [...data];
    const index = currentItems.findIndex(
      (itemDetail: any) => itemDetail.id === id,
    );
    if (currentItems[index].quantity > 0) {
      currentItems[index].quantity -= 1;
      setData(currentItems);
    }
  };

  const itemChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: any,
  ) => {
    const { value } = event.target;
    let quantity = parseInt(value, 10);

    if (isNaN(quantity) || quantity < 0) {
      quantity = 0;
    }
    const currentItems = [...data];
    const index = currentItems.findIndex(
      (itemDetail: any) => itemDetail.id === item.id,
    );
    if (index !== -1) {
      currentItems[index].quantity = quantity;
      setData(currentItems);
    }
  };

  const handleFilterService = async (clothList: any) => {
    setSelectService(clothList);
    const list: any = getGroupByCategoryWithSectionName(data)[clothList];
    await setClothesList(list);
  }
  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchInput(event.target.value);
  };

  const handleSearchInputChangeAccordion = (
    sectionName: any,
    event: any,
  ) => {
    setSearchQueries({
      ...searchQueries,
      [sectionName]: event?.target?.value.toLowerCase()
    });
  };
  const filteredClothesList = clothesList?.map((section: any)=> ({...section,optiServiceItem : section.optiServiceItem.filter((item: any) => item.item?.toLowerCase().includes(searchQueries[item.serviceItemSectionName] ? searchQueries[item.serviceItemSectionName] : '')) }))

  const filteredOptiServiceItem = clothesList?.map((section: any)=> ({...section,optiServiceItem : section.optiServiceItem.filter((item: any) => item.itemCode?.toLowerCase().includes(searchInput?.toLowerCase()) || item.item?.toLowerCase().includes(searchInput?.toLowerCase())) }))

  return (
    <>
              <Grid container spacing={1} padding={'0px 10px 10px 0px'}>
            {clothesFilterList?.length > 0 ? (clothesFilterList?.map((item: string, index: number)=>(
                <Grid item key={index}>
                    <Chip
                        color="primary"
                        size="medium"
                        variant={selectService === item ? "filled" : "outlined"}
                        // className="m-2"
                        sx={{color: selectService === item ? 'white' : 'primary'}}
                        label={item}
                        onClick={() => handleFilterService(item)}
                    />
                </Grid>
            ))) : (<Typography
              variant="subtitle1"
              color={'GrayText'}
              sx={{ fontSize: '14px', padding: '30px 40%' }}
            >
              No Items to show{' '}
            </Typography>)}
{          filteredOptiServiceItem?.length === 1?  <Grid item>
                        <TextField
                            variant="outlined"
                            placeholder="Search Item code"
                            id="inputSearchItems"
                            value={searchInput}
                            onChange={handleSearchInputChange}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& input': {
                                  padding: '8px 14px', // Adjust the padding to fit the new height
                                },
                              },
                            }}
                          />
                      </Grid>: <></>}
        </Grid>
      <div
                style={{ height: '100%', overflow: 'auto', padding: '5px 20px' }}
              >
                {filteredOptiServiceItem?.length === 1 ? filteredOptiServiceItem?.map(
                  (item: any, index: number) => { return(                     
                  <CartList
                    filteredClothesList={item.optiServiceItem}
                    index={item?.id}
                    name={item?.item}
                    price={item?.price}
                    type={item?.category as TCategoryType}
                    currency={item?.currency}
                    icon={item?.icon}
                    key={`laundryTile${index}`}
                    itemChangeHandler={itemChangeHandler}
                    count={item?.quantity}
                    onIncrement={(i) => increaseQuantity(i)}
                    onDecrement={(i) => decreaseQuantity(i)}
                  />)},
                ):filteredClothesList?.map(
                  (item: any, index: number) => { return(<Accordion
                    expanded={expanded === `panel${index}`}
                    onChange={handleExpanded(`panel${index}`)}
                    key={`panel${index}`}
                     sx={{
                       backgroundColor: 'transparent',
                       border: "1px solid purple",
                       // color: 'white',
                       padding: '0.5rem 0.5rem',
                       margin: '0.5rem 0rem'
                     }}
                   >
                     <AccordionSummary
                       sx={{ padding: '0px', textAlign: 'center' }}
                       expandIcon={<ExpandMoreIcon />}
                       aria-controls="panel1-content"
                       id="panel1-header"
                     >
                           <Grid
                             container
                             display={'flex'}
                             direction={'row'}
                             justifyContent={'space-between'}
                           >
                             <Grid item>
                               <DialogContentText sx={{ fontSize: '14px', color: 'black' }}>
                                 {item?.serviceItemSectionName}
                               </DialogContentText>
                             </Grid>
                           <Grid item>
                        <TextField
                            key={item?.id}
                            variant="outlined"
                            placeholder="Search..."
                            id="inputSearchItem"
                            // value={item?.searchInput}
                            onChange={(e) =>handleSearchInputChangeAccordion(item?.serviceItemSectionName,e)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& input': {
                                  padding: '8px 14px', // Adjust the padding to fit the new height
                                },
                              },
                            }}
                          />
                      </Grid>
                           </Grid>
                     </AccordionSummary>
                     <AccordionDetails
                       sx={{ display: 'flex', flexDirection: 'column', padding: '0px' }}
                     >
                     <CartList
                     filteredClothesList={item.optiServiceItem}
                     index={item?.id}
                     name={item?.item}
                     price={item?.price}
                     type={item?.category as TCategoryType}
                     currency={item?.currency}
                     icon={item?.icon}
                     key={`laundryTile${index}`}
                     itemChangeHandler={itemChangeHandler}
                     count={item?.quantity}
                     onIncrement={(i) => increaseQuantity(i)}
                     onDecrement={(i) => decreaseQuantity(i)}
                   />
                     </AccordionDetails>
                   </Accordion>)},
                )}
              </div>
      <Modal open={open} onClose={handleModalClose} disableEnforceFocus>
        <Box
          sx={{ ...modalStyle }}
          className="cart-modal"
          style={{ background: getCategoryColor(selectedItem?.category) }}
        >
          <IconButton
            className="cart-modal-close"
            onClick={() => {
              setOpen(false);
              setSelectedItem(null);
            }}
          >
            <img src={Icons.close} alt="closeIcon" id="closeIcon" />
          </IconButton>
          <Box className="cart-modal-info">
            <img
              src={selectedItem?.icon}
              className="cart-modal-img"
              width={'150px'}
              alt="icon"
              id="icon"
            />
            <p className="cart-modal-name">{selectedItem?.item}</p>
            <p className="cart-modal-price">
              {selectedItem?.currency}
              {selectedItem?.price}
            </p>
          </Box>
          <ul
            className="cart-modal-actions" 
          >
            <li style={{ width: '25%' }}>
              <button
                className="cart-modal-actions-decrement-btn"
                onClick={() => decreaseQuantity(selectedItem.id)}
              >
                -
              </button>
            </li>
            <li style={{ width: '50%' }}>
              <input
                type="number"
                value={data[selectedItem?.id]?.quantity}
                onChange={(e) => {
                  setData((pv: any) => {
                    pv[selectedItem.id].quantity = +e.target.value;
                    return [...pv];
                  });
                }}
              />
            </li>
            <li style={{ width: '25%' }}>
              <button
                className="cart-modal-actions-increment-btn"
                onClick={() => increaseQuantity(selectedItem.id)}
              >
                +
              </button>
            </li>
          </ul>
        </Box>
      </Modal>
    </>
  );
};

export default CategoryWidget;
