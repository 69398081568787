import React, { useContext, useEffect, useState } from 'react';
import { Container, Stack, TextField, Typography, Grid, Link } from '@mui/material';
import './OrderLogin.scss';
import { useAPI } from '../../../shared/services/api/API';
import { useNavigate } from 'react-router-dom';
import { AuthenticationContext } from '../../../shared/Contexts';
import { WASHUB_CONSTANTS } from '../../../shared/Constants';
import { CustomButton } from '../CustomButton';
import RefreshIcon from '@mui/icons-material/Refresh';

export const OrderLogin = () => {
  const { httpPost } = useAPI();
  const navigate = useNavigate();
  const authenticate = useContext(AuthenticationContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showOTP, setShowOTP] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [captchaText, setCaptchaText] = useState('');
  const [userInput, setUserInput] = useState('');

  const handleEmailChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setPassword(e.target.value);
  };

  const emailRegex = /^[A-Za-z0-9._%+-]+$/i;
  const generateOTP = async () => {
    if (!email.match(emailRegex)) {
      setVerificationStatus('Please enter a valid email address.');
      return;
    }
    if (userInput !== captchaText) {
      setVerificationStatus("Please enter a correct captcha.");
      initializeCaptcha();
      return
    }
    try {
      const queryParams = {
        userId: email,
        password: password,
      };
      const response = await httpPost(
        WASHUB_CONSTANTS.API_URLS.AUTH_BUSUSERLOGIN,
        null,
        queryParams,
      );
      console.log('Response:', response);
      if (!response.data.error) {
        setVerificationStatus('Login successful.');
        setShowSuccess(true);
        const jwtToken: string = response?.data?.token || null;
        localStorage.setItem('jwtToken', jwtToken);
        localStorage.setItem('clientDetail', JSON.stringify(response?.data?.client));
        localStorage.setItem('busDetail', JSON.stringify(response?.data?.business));
        authenticate.updateToken(jwtToken);
        navigate('/OrderCenter/home');
      } else {
        setVerificationStatus(
          response?.data?.message || 'Please enter a valid User / Password .',
        );
        setNameError(true);
      }
    } catch (error) {
      console.error('Error generating Login:', error);
      setVerificationStatus('Error generating Login.');
    }
  };

  const verifyOTP = async () => {
    try {
      const queryParams = {
        userId: email,
        password: password,
      };
      const response = await httpPost(
        WASHUB_CONSTANTS.API_URLS.AUTH_LOGIN_VERIFY_OTP,
        null,
        queryParams,
      );
      console.log('Response:', response.data);
      setVerificationStatus('OTP verification successfully');
      setShowSuccess(true);
      const jwtToken: string = response?.data?.token || null;
      localStorage.setItem('jwtToken', jwtToken);
      authenticate.updateToken(jwtToken);
      navigate('/OrderCenter/home');
    } catch (error) {
      setVerificationStatus('OTP verification failed');
    }
  };

  useEffect(() => {
    initializeCaptcha();
  }, []);

  const generateCaptchaText = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let captcha = '';
      for (let i = 0; i < 6; i++) {
          captcha += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return captcha;
  };

  const initializeCaptcha = () => {
      setUserInput('');
      const newCaptcha = generateCaptchaText();
      setCaptchaText(newCaptcha);
  };

  const handleUserInputChange = (e: any) => {
      setUserInput(e.target.value);
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '83vh',
        '@media (min-width:0px)': {
          minHeight: '83vh',
        },
        '@media (min-width:600px)': {
          minHeight: '80vh',
        },
        '@media (min-width:960px)': {
          minHeight: '70vh',
        },
        '@media (min-width:1280px)': {
          minHeight: '60vh',
        },
        '@media (min-width:1920px)': {
          minHeight: '50vh',
        },
      }}
    >
      <Stack>
        <Stack>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: '83vh' }}
          >
            <Grid item xs={12} sm={6} md={6} lg={5} xl={4} sx={{display: 'flex', justifyContent: 'center'}}>
              <img
                src="../../../../images/laundry1.png"
                alt="Login Icon"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={5}
              xl={4}
              sx={{padding: '20px'}}
            >
              <Typography variant="h4" gutterBottom className="header">
                Login
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: verificationStatus.includes('successful')
                    ? 'green'
                    : 'red',
                  m: 4,
                }}
                className="verification-status"
              >
                {verificationStatus}
              </Typography>
              <TextField
              fullWidth
                placeholder="Username / Email"
                variant="standard"
                type="email"
                value={email}
                onChange={handleEmailChange}
                error={nameError}
                helperText={nameError ? 'Please enter your name' : ''}
                sx={{ mb: 2, paddingTop: '40px' }}
              />
              <TextField
              fullWidth
                placeholder="Password"
                variant="standard"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                sx={{ mb: 2, paddingTop: '20px' }}
              />
              <div className="wrapper">
                    <div style={{fontSize:"20px", fontWeight:"bold", letterSpacing: "5px"}}>{captchaText}</div>
                    <Link className="mt-1" id="reload-button" onClick={
                        () => initializeCaptcha()}>
                    <RefreshIcon/>
                    </Link>
                </div>
                <TextField
                    placeholder="Enter the captcha"
                    variant="standard"              
                    type="captcha"
                    value={userInput}
                    onChange={handleUserInputChange}
                    sx={{ mb: 2 ,  paddingTop: "10px", width: "100%" }}
                  />
              <CustomButton
                variant="contained"
                label={'Submit'}
                onClick={generateOTP}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Container>
  );
};
